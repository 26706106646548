import './style.css';
import { EXTERNAL_URI } from '@constants';
import hyundaiLive from '@images/hyundaiLive.svg';
import { renderToString } from 'react-dom/server';

window.addEventListener('load', () => {
  const webUrl = EXTERNAL_URI;
  // const buttonClassName = '.open-hlive-consult';
  const generateUrl = ({ channel, modelid, dealershipcode }) => {
    return encodeURI(`${webUrl}/it/en/q?channel=${channel}&modelId=${modelid}&dealershipCode=${dealershipcode}`);
  };
  let matches = document.querySelectorAll('[data-liveconsult]');
  if (document.createStyleSheet) {
    document.createStyleSheet('https://hlivedev.blob.core.windows.net/hlive-button-script/static/css/url.58f0b5e3.css');
  } else {
    var styles = "@import url('https://hlivedev.blob.core.windows.net/hlive-button-script/static/css/url.58f0b5e3.css');";
    var newSS = document.createElement('link');
    newSS.rel = 'stylesheet';
    newSS.href = 'data:text/css,' + styles;
    document.getElementsByTagName('head')[0].appendChild(newSS);
  }
  matches.forEach((item) => {
    let { channel, modelid, dealershipcode } = item.dataset;
    let linkurl = generateUrl({ channel, modelid, dealershipcode });
    let className = item.getAttribute('class') || '';

    item.setAttribute('class', className.concat(' btn-hlive btn-hlive-default'));
    item.innerHTML = renderToString(
      <img
        src={'https://hlive.azureedge.net/hlive-button-script/static/media/hyundaiLive.6cd103f111537b63da0ae564bb20c167.svg'}
        alt="hyundaiLive"
      ></img>,
    );
    item.addEventListener('click', (e) => {
      window.open(linkurl, '_blank');
    });
  });
});
